import React from "react"
import { connect } from "react-redux"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider2 from "../components/slider2"
import { BASE_URL } from "../utils/urls"

import {
  removeToCart,
  incrementCartProduct,
  decrementCartProduct,
} from "../store/actions/productActions"
import { Link, navigate } from "gatsby"

const Cart = ({
  cartProducts,
  incrementCartProduct,
  decrementCartProduct,
  removeToCart,
  subTotalAmount,
  shippingAmount,
}) => {
  const pageName = "PANIER"
  return (
    <Layout>
      <SEO title={pageName} />
      <Slider2 title={pageName} />
      <div className="flex flex-wrap py-6">
        <div className="container mx-auto">
          <div className="flex flex-col justify-center">
            <span className="text-center text-sm pt-2 font-semibold text-blue-600">
              Mon panier
            </span>
            <h1 className="text-center text-2xl">
              Retrouvez le linge que vous souhaitez nous confier ici!
            </h1>
            <span className="text-gray-600 text-xs text-center px-4 pb-8">
              {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. */}
              <br />
              {/* Explicabo minima, labore excepturi facilis velit */}
            </span>
          </div>
          <div className="w-full">
            {cartProducts.length === 0 ? (
              <div
                className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative text-sm text-center"
                role="alert"
              >
                <strong className="font-bold">Oups 😭 !</strong> &nbsp;
                <span className="block sm:inline">
                  <Link to="/all-clothes">
                    Aucun vêtement ajouté au panier. Souhaitez-vous en ajouter
                    un ?
                  </Link>
                </span>
                <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                  <svg
                    className="fill-current h-6 w-6 text-blue-500"
                    role="button"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <title>Fermer</title>
                    <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                  </svg>
                </span>
              </div>
            ) : (
              <div className="flex flex-wrap">
                <table className="w-full table-auto text-sm">
                  <thead>
                    <tr>
                      <th className="w-1/4 px-4 py-2 text-center"></th>
                      <th className="w-1/3 px-4 py-2 text-center">Produit</th>
                      <th className="w-1/4 px-4 py-2 text-center">
                        Prix unitaire
                      </th>
                      <th className="w-1/4 px-4 py-2 text-center">Quantité</th>
                      <th className="w-1/4 px-4 py-2 text-center">Total</th>
                      <th className="w-1/4 px-4 py-2 text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartProducts.map((product, key) => (
                      <tr key={key}>
                        <td className="border px-4 py-2 text-center">
                          <img
                            src={BASE_URL + "/storage/" + product.cover}
                            className="w-20 mx-auto"
                            alt=""
                            srcSet=""
                          />
                        </td>
                        <td className="border px-4 py-2 text-center">
                          {product.name}
                        </td>
                        <td className="border px-4 py-2 text-center">
                          {product.selling_price + " FCFA"}
                        </td>
                        <td className="border px-4 py-2 text-center">
                          <button
                            onClick={() => incrementCartProduct(product.id)}
                            className="px-2 py-0 rounded-full text-white text-sm bg-blue-600 border-blue-600 hover:bg-blue-900 hover:border-blue-900 w-full md:w-1/6"
                          >
                            +
                          </button>
                          <span className="px-2 text-center w-full md:w-1/2">
                            {product.quantity}
                          </span>
                          <button
                            onClick={() => decrementCartProduct(product.id)}
                            className="px-2 py-0 rounded-full text-white text-sm bg-blue-600 border-blue-600 hover:bg-blue-900 hover:border-blue-900 w-full md:w-1/6"
                          >
                            -
                          </button>
                        </td>
                        <td className="border px-4 py-2 text-center">
                          {product.selling_price * product.quantity + " FCFA"}
                        </td>
                        <td className="border px-4 py-2 text-center">
                          <button
                            onClick={() => removeToCart(product.id)}
                            className="px-3 py-0 rounded-full text-white bg-green-500 border-green-500 hover:bg-green-700 hover:border-red-900"
                          >
                            Supprimer
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="w-1/2"></div>
                <div className="w-full md:w-1/2 mx-2 md:mx-0">
                  <div className="flex flex-wrap text-sm py-1">
                    <span className="w-1/2 text-left">Sous total</span>
                    <span className="w-1/2 text-right">
                      {subTotalAmount} FCFA
                    </span>
                  </div>
                  <hr />
                  <div className="flex flex-wrap text-sm py-1">
                    <span className="w-1/2 text-left">Frais de Livraison</span>
                    <span className="w-1/2 text-right">
                      {shippingAmount} FCFA
                    </span>
                  </div>
                  <hr />
                  <div className="flex flex-wrap text-base py-2">
                    <span className="w-1/2 font-bold text-left">Total</span>
                    <span className="w-1/2 font-bold text-right">
                      {shippingAmount + subTotalAmount} FCFA
                    </span>
                  </div>
                  <hr />
                  <div className="py-2 text-center flex flex-wrap justify-center -mx-2 md:mx-0">
                    <div className="w-1/2 md:w-1/3 px-2 md:px-0">
                      <button
                        onClick={() => navigate("/all-clothes")}
                        className="py-2 px-2 w-full rounded-full text-white bg-green-500 border-green-500 hover:bg-green-700 hover:border-red-900 text-xs md:text-sm"
                      >
                        Revenir aux vêtements
                      </button>
                    </div>
                    <div className="hidden md:block md:w-1/3"></div>
                    <div className="w-1/2 md:w-1/3 px-2 md:px-0">
                      <button
                        onClick={() => navigate("/checkout")}
                        className="py-2 px-2 w-full rounded-full text-white bg-blue-600 border-blue-600 hover:bg-blue-900 hover:border-blue-900 text-xs md:text-sm"
                      >
                        Commander
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    cartProducts: state.product.cart,
    subTotalAmount: state.product.subTotalAmount,
    shippingAmount: state.product.shippingAmount,
  }
}

const mapDispatchToProps = {
  removeToCart,
  incrementCartProduct,
  decrementCartProduct,
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart)
